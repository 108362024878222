import { GetApiClient } from "@resposta-certa/api-client";
import { useAuthStore } from "~/stores/auth";

export default function useApi() {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const client = GetApiClient(config.public.apiURL as string, authStore.token);

  return client;
}

export { GetApiClient };
